import { Image } from 'antd'
import '../index.less'

const FeatCard = ({
  item
}) => {
  return (
    <div className="card flex">
      <Image
        className="w88 h88"
        src={item?.image?.default}
        preview={false}
      />
      <div className="flex1" style={{ marginLeft: 14 }}>
        <div className="font-medium fs20 text-color-footer">
          {item?.title}
        </div>

        <div className="twoLine font-regular fs16 text-color">
          {item?.description}
        </div>
      </div>
    </div>
  )
}

export default FeatCard
