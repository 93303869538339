
function changeSize(){
  let designSize = 1920
  let html = document.documentElement
  let clientW = html.clientWidth
  let htmlRem = clientW / designSize

  html.style.fontSize = htmlRem + 'px'
  document.body.style.fontSize = htmlRem + 'px'
}

setTimeout(changeSize(),0)
window.addEventListener("load", changeSize);
window.addEventListener('resize', changeSize)
