import { Image } from 'antd'

const ProCard = ({
  item
}) => {
  return (
    <div>
      <Image
        src={item?.src?.default}
        preview={false}
      />

      <div className="fs32 fw500 text-color-footer font-medium text-color-footer mt24 mb16 wsno">
        {item?.title}
      </div>

      {item?.content?.map((el, i) => (
        <div key={i} className="fs24 fw400 lh48 text-color font-regular wsno vam">
          <i className="ib w8 h8 mr16 bdrs8 mt-4 vam" style={{ background: item?.color }} />
          {el}
        </div>
      ))}
    </div>
  )
}

export default ProCard
